import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue from "vue";

Vue.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
});

const config = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  rtl: false,
};

const configSuccess = {
  ...config,
  icon: {
    iconClass: "fa fa-check",
    iconChildren: "",
    iconTag: "i",
  },
};

const defaultConfig = {
  ...config,
  icon: true,
};

export const error = (message) => {
  Vue.$toast.error(message, defaultConfig);
};

export const success = (message) => {
  Vue.$toast.warning(message, configSuccess);
};

export const info = (message) => {
  Vue.$toast.info(message, defaultConfig);
};
